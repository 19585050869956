import { Box } from "@mui/material";
import React from "react";
import LinkSideBar from "../LinkSidebar";
import style from "./styles.module.scss";

interface LayoutProps {
  children: React.ReactNode;
  hasBottomMargin?: boolean;
}

export const Layout = ({ children, hasBottomMargin = true }: LayoutProps) => {
  return (
    <Box>
      <div
        className={`${style.containerLayout} ${
          !hasBottomMargin ? style.noBottomMargin : ""
        }`}
      >
        {children}
      </div>
      <LinkSideBar />
    </Box>
  );
};
