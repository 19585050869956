import api from "../../services/api/api";

export const userApi = {
  postPointRegister: async (user: any, data: any): Promise<any> => {
    return api.post(
      `/company/${user.employee.company_id}/pointRegister/mark`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  async getBankOfHours(
    company_id: number,
    start_date: string,
    end_date: string
  ) {
    return await api.get(
      `company/${company_id}/compensatoryTime?dateStart=${start_date}&dateEnd=${end_date}`
    );
  },
};
