import { Layout } from "../../components/Layout";
import { BoxContainerLayout } from "../../components/BoxContainerLayout";
import { Banner } from "../../components/Banner";
import { IoIosNotifications } from "react-icons/io";
import style from "./styles.module.scss";
import { useNotifications } from "../../stores/notifications";
import { switchCaseIcons } from "../../utils/switchCaseIcons";

export default function Notify() {
  const notifications = useNotifications();

  return (
    <>
      <Layout>
        <Banner text={"Notificações"} icon={<IoIosNotifications />} />
        <BoxContainerLayout>
          <div className={style.notify}>
            {notifications.length === 0 ? (
              <p>Sem Notificações</p>
            ) : (
              notifications.map((notification: any, index) => (
                <div
                  key={index}
                  className={style.notification}
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    padding: "1rem",
                    marginBottom: "1rem",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    transition: "transform 0.2s ease",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={style.header}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {switchCaseIcons(notification.data?.status)}
                    <h3
                      style={{
                        margin: 0,
                        fontSize: "1.1rem",
                        fontWeight: 600,
                        color: "#2c3e50",
                      }}
                    >
                      {notification.notification?.title}
                    </h3>
                  </div>
                  <p
                    style={{
                      margin: "0.5rem 0",
                      color: "#34495e",
                      lineHeight: "1.4",
                    }}
                  >
                    {notification.notification?.body}
                  </p>
                  <div
                    className={style.footer}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "0.5rem",
                      paddingTop: "0.5rem",
                      borderTop: "1px solid #eee",
                    }}
                  >
                    <small
                      style={{
                        color: "#7f8c8d",
                        fontSize: "0.85rem",
                      }}
                    >
                      {new Date().toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </small>
                  </div>
                </div>
              ))
            )}
          </div>
        </BoxContainerLayout>
      </Layout>
    </>
  );
}
