import { HourglassBottom } from "@mui/icons-material";
import { Banner } from "../../components/Banner";
import { Layout } from "../../components/Layout";
import style from "./styles.module.scss";
import Button from "../../components/Button";
import { useSteps } from "../../hooks/step";
import { If } from "../../components/If";
import { useAllowance } from "../../providers/Allowance";
import { useEffect, useState, useCallback, useRef } from "react";
import { AllowanceType } from "../../providers/Allowance/types";
import { AllowanceProps, CardAllowance } from "../../components/CardAllowance";
import { AllowanceTypeStep2 } from "../../components/Allowance/AllowanceStep2";

export const Allowance = () => {
  const { step, handleNextStep, handleBackStep } = useSteps();
  const { getAllowanceTypes, getAllowance, sendAllowance } = useAllowance();
  const [allowanceTypes, setAllowanceTypes] = useState<AllowanceType[]>([]);
  const [allowances, setAllowances] = useState<AllowanceProps[]>([]);
  const [selectedType, setSelectedType] = useState<number | "">("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastCardRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchAllowance = async (currentPage: number) => {
    setLoading(true);
    try {
      const response = await getAllowance(currentPage);
      if (response?.data) {
        setAllowances((prev) =>
          currentPage === 1 ? response.data : [...prev, ...response.data]
        );
        setHasMore(response.current_page < response.last_page);
      }
    } catch (error) {
      console.error("Error fetching allowances:", error);
    }
    setLoading(false);
  };

  const fetchAllowanceTypes = async () => {
    const allowanceTypesResponse = await getAllowanceTypes();
    setAllowanceTypes(allowanceTypesResponse || []);
  };

  useEffect(() => {
    fetchAllowanceTypes();
  }, []);

  useEffect(() => {
    fetchAllowance(page);
  }, [page]);

  const handleTypeChange = (event: any) => {
    setSelectedType(event.target.value);
  };

  return (
    <Layout hasBottomMargin>
      <If condition={step === 1}>
        <Banner text="Meus Abonos" icon={<HourglassBottom />} />
        <div className={style.container}>
          <div className={style["allowance-button"]} onClick={handleNextStep}>
            <Button variant="secondary" type="button">
              Solicitar abono
            </Button>
          </div>
          <div className={style.list}>
            {allowances.length > 0 ? (
              allowances.map((allowance, index) => (
                <div
                  key={`${allowance.id}-${index}`}
                  ref={index === allowances.length - 1 ? lastCardRef : null}
                >
                  <CardAllowance {...allowance} />
                </div>
              ))
            ) : (
              <p>Nenhum abono encontrado</p>
            )}
            {loading && page > 1 && (
              <div className={style.loadingMore}>Carregando mais...</div>
            )}
          </div>
          {!hasMore && allowances.length > 0 && (
            <div className={style.loadingMore}>
              Não há mais itens para carregar.
            </div>
          )}
        </div>
      </If>

      <If condition={step === 2}>
        <AllowanceTypeStep2
          types={allowanceTypes}
          selectedType={selectedType}
          onTypeChange={handleTypeChange}
          onBack={handleBackStep}
          onSubmit={async (data) => {
            await sendAllowance(data);
            await fetchAllowance(1);
          }}
        />
      </If>
    </Layout>
  );
};
