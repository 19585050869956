// STEP 2 CREATE

import styled from "@emotion/styled";
import { HourglassBottom } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { CloudUpload } from "lucide-react";
import { useState } from "react";
import { useAllowance } from "../../../providers/Allowance";
import { ALLOWANCE_TYPES } from "../../../providers/Allowance/constants";
import { AllowanceType } from "../../../providers/Allowance/types";
import { customToast } from "../../../utils/toast";
import { Banner } from "../../Banner";
import style from "./styles.module.scss";
import Button from "../../Button";
interface AllowanceTypeStep2Props {
  types: AllowanceType[];
  selectedType: number | "";
  onTypeChange: (event: any) => void;
  onBack: () => void;
  onSubmit: (data: any) => Promise<void>;
}

interface AllowanceForm {
  doctorName: string;
  cid: string;
  crm: string;
  location: string;
  startDate: string;
  endDate: string;
  document: File | null;
  previewUrl?: string;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export const AllowanceTypeStep2 = ({
  types,
  selectedType,
  onTypeChange,
  onBack,
  onSubmit,
}: AllowanceTypeStep2Props) => {
  const today = new Date().toISOString().split("T")[0];
  const [loading, setLoading] = useState(false);
  const { addDocumentAllowance } = useAllowance();
  const [form, setForm] = useState<AllowanceForm>({
    doctorName: "",
    cid: "",
    crm: "",
    location: "",
    startDate: today,
    endDate: today,
    document: null,
    previewUrl: "",
  });

  const handleFormChange =
    (field: keyof AllowanceForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setForm((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: "startDate" | "endDate"
  ) => {
    const newDate = e.target.value;

    if (field === "endDate" && newDate < form.startDate) {
      return;
    }

    setForm((prev) => ({
      ...prev,
      [field]: newDate,
    }));
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      try {
        const uploadedFileUrl = await addDocumentAllowance(file);
        if (typeof uploadedFileUrl === "string") {
          setForm((prev) => ({
            ...prev,
            document: file,
            previewUrl: uploadedFileUrl,
          }));
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Erro ao fazer upload do arquivo");
      }
    }
  };

  const selectedAllowanceType = types.find((t) => t.id === selectedType)?.name;
  const isMedicalCertificate =
    String(selectedType) === String(ALLOWANCE_TYPES.MEDICAL_CERTIFICATE);

  const handleSubmit = async () => {
    if (!selectedType) {
      customToast("Selecione um tipo de abono", "error");
      return;
    }

    if (isMedicalCertificate) {
      if (!form.doctorName) {
        customToast("Informe o nome do médico", "error");
        return;
      }

      if (!form.crm) {
        customToast("Informe o CRM", "error");
        return;
      }

      if (!form.location) {
        customToast("Informe o local", "error");
        return;
      }
    }

    const data = {
      point_allowance_type_id: selectedType,
      start_date: form.startDate,
      end_date: form.endDate,
      is_medical_certificate: isMedicalCertificate,
      doctor_name: form.doctorName,
      crm: form.crm,
      service_location: form.location,
      cid: form.cid || "",
      allowance_reason: selectedAllowanceType,
      allowance_attachment: form.previewUrl,
    };

    try {
      setLoading(true);
      await onSubmit(data);
      onBack();
    } catch (error) {
      console.error("Error submitting allowance:", error);
      alert("Erro ao enviar solicitação");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Banner text="Solicitar Abono" icon={<HourglassBottom />} />
      <div className={style.container}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="allowance-type-label">Tipo de abono</InputLabel>
          <Select
            labelId="allowance-type-label"
            id="allowance-type-select"
            value={selectedType}
            label="Tipo de abono"
            onChange={onTypeChange}
            disabled={loading}
          >
            {types.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedType && (
          <div className={style.form}>
            <div className={style.dateControls}>
              <TextField
                fullWidth
                type="date"
                label="Data Inicial"
                value={form.startDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDateChange(e, "startDate")
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: today }}
                sx={{ marginRight: 1 }}
                disabled={loading}
              />
              <TextField
                fullWidth
                type="date"
                label="Data Final"
                value={form.endDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDateChange(e, "endDate")
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: form.startDate }}
                sx={{ marginLeft: 1 }}
                disabled={loading}
              />
            </div>

            {isMedicalCertificate && (
              <>
                <TextField
                  fullWidth
                  label="Médico"
                  placeholder="Digite o nome do médico"
                  value={form.doctorName}
                  onChange={handleFormChange("doctorName")}
                  sx={{ marginTop: 2 }}
                  disabled={loading}
                />
                <div
                  style={{ display: "flex", gap: "16px", marginTop: "16px" }}
                >
                  <TextField
                    fullWidth
                    label="CID"
                    placeholder="Digite o CID"
                    value={form.cid}
                    onChange={handleFormChange("cid")}
                    disabled={loading}
                  />
                  <TextField
                    fullWidth
                    label="CRM"
                    placeholder="Digite o CRM do médico"
                    value={form.crm}
                    onChange={handleFormChange("crm")}
                    disabled={loading}
                  />
                </div>
                <TextField
                  fullWidth
                  label="Local"
                  placeholder="Digite o endereço"
                  value={form.location}
                  onChange={handleFormChange("location")}
                  sx={{ marginTop: 2 }}
                  disabled={loading}
                />

                <div style={{ marginTop: "16px" }}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUpload />}
                    className={style.uploadButton}
                  >
                    {form.document ? form.document.name : "Anexar documento"}
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                      onChange={handleFileChange}
                      disabled={loading}
                    />
                  </Button>
                </div>
              </>
            )}
          </div>
        )}

        <div className={style.actions}>
          <Button
            variant="outlined"
            type="button"
            onClick={onBack}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button variant="secondary" onClick={handleSubmit} disabled={loading}>
            {loading ? "Enviando..." : "Solicitar"}
          </Button>
        </div>
      </div>
    </>
  );
};
