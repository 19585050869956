import { useState } from "react";

export const useSteps = () => {
  const [step, setStep] = useState<number>(1);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  return { step, handleNextStep, handleBackStep };
};
