import { createContext, useCallback, useContext, useMemo } from "react";
import { AllowanceType, IAllowanceContextData, SendAllowance } from "./types";
import { allowanceService } from "./service";
import { customToast } from "../../utils/toast";

export const AllowanceContext = createContext<IAllowanceContextData>(
  {} as IAllowanceContextData
);

export const AllowanceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const service = allowanceService();

  const getAllowance = useCallback(
    async (page = 1) => {
      try {
        return await service.getAllowance(page);
      } catch (error) {
        customToast("Erro ao listar abonos", "danger");
        return [];
      }
    },
    [service]
  );

  const sendAllowance = useCallback(
    async (data: SendAllowance) => {
      try {
        await service.sendAllowance(data);
        customToast("Solicitação enviada com sucesso", "success");
      } catch (error) {
        customToast("Erro ao enviar solicitação", "danger");
      }
    },
    [service]
  );

  const addDocumentAllowance = useCallback(
    async (file: any) => {
      try {
        return await service.addDocumentAllowance(file);
      } catch (err) {
        return customToast("Erro ao adicionar documento", "danger");
      }
    },
    [service]
  );

  const getAllowanceTypes = useCallback(async (): Promise<AllowanceType[]> => {
    try {
      return await service.getAllowanceTypes();
    } catch (err) {
      customToast("Erro ao buscar os tipos de abono", "danger");
      return [];
    }
  }, [service]);

  const value = useMemo(() => {
    return {
      sendAllowance,
      addDocumentAllowance,
      getAllowance,
      getAllowanceTypes,
    };
  }, [sendAllowance, addDocumentAllowance, getAllowance, getAllowanceTypes]);

  return (
    <AllowanceContext.Provider value={value}>
      {children}
    </AllowanceContext.Provider>
  );
};

export const useAllowance = () => useContext(AllowanceContext);
