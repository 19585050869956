import { ButtonHTMLAttributes } from "react";

import styles from "./styles.module.scss";

type ButtonProps = (
  | (ButtonHTMLAttributes<HTMLButtonElement> & { component?: "button" })
  | (React.LabelHTMLAttributes<HTMLLabelElement> & { component: "label" })
) & {
  variant?: "contained" | "outlined" | "text" | "secondary";
  startIcon?: React.ReactNode;
  className?: string;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  component: Component = "button",
  startIcon,
  className,
  loading,
  ...rest
}) => {
  const props = {
    className: `${styles.buttonStyle} ${className || ""}`,
    disabled: loading,
    ...(Component === "button"
      ? rest
      : (rest as React.LabelHTMLAttributes<HTMLLabelElement>)),
  };

  return (
    // @ts-ignore
    <Component {...props}>
      {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
      {children}
    </Component>
  );
};

export default Button;
