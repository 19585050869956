import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB2EmSjSGe6a_zR-qI9e6MyqB52GuQGQDk",
  authDomain: "testeeasypoint.firebaseapp.com",
  projectId: "testeeasypoint",
  storageBucket: "testeeasypoint.appspot.com",
  messagingSenderId: "1021128403302",
  appId: "1:1021128403302:web:545492b276f7468def80a7",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const publicKey =
  "BF-M7GDqKd31A8-8M9TexsNZez4IShI_6Ol0Cz_b2M3tjYt_LsYF7Gd5EW67aobaGKJ18pXyf1I5sQoLE3j_0i4";

onMessage(messaging, (payload) => {
  console.log("Foreground Message received. ", payload);
});
export const handleToken = async (messaging: Messaging, vapidKey: string) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.log("Notification permission denied");
      return null;
    }

    let response = await getToken(messaging, {
      vapidKey: vapidKey,
    });

    if (!response) {
      console.log("Didn't fetch token");
      return null;
    }
    return response;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
