import { useCallback, useEffect } from "react";
import style from "./styles.module.scss";
import { CardHome } from "../CardHome";
import { TbCalendarStats } from "react-icons/tb";
import { useUser } from "../../providers/User";
export const Card = () => {
  const { getShift, setShift, shift, getMe } = useUser();

  const loadShifts = useCallback(async () => {
    const storageUser = localStorage.getItem("@Auth:user");
    if (storageUser) {
      const userData = JSON.parse(storageUser);
      return getShift(userData.employee.company_id, setShift);
    } else {
      try {
        getMe().then((data: any) => {
          return getShift(data.data.response.employee.company_id, setShift);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [getMe, getShift, setShift]);

  useEffect(() => {
    loadShifts();
  }, [loadShifts]);

  const getDayActual = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return day + "/" + month;
  };
  return (
    <>
      {/* <CardBankHours /> */}

      <div className={style.card}>
        <div className={style.card2}>
          <div className={style.containerTitle}>
            <span>
              {<TbCalendarStats fontSize={"1.5rem"} /> ?? "Não informado"}
            </span>
            <h5>{shift[0]?.week_day.description ?? "Não informado"}</h5>
            <div className={style.dateTimeContainer}>
              <span>{getDayActual() ?? "Não informado"}</span>
            </div>
          </div>
        </div>

        {shift.length > 0 &&
          shift.map((item: any, index: any) => {
            return (
              <div key={index} className={style.cardHome}>
                <CardHome item={item} />
              </div>
            );
          })}
      </div>
    </>
  );
};
