import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
export const HourNow = () => {
  const [currentTime, setCurrentTime] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      setCurrentTime(`${hours}h${minutes}m`);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={style.hour}>
      <span>{currentTime}</span>
    </div>
  );
};
