/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react";
// @ts-ignore
import { Allowance, AllowanceType, SendAllowance } from "./types";
import api from "../../services/api/api";

export const allowanceService = () => {
  const getAllowance = useCallback(async (page = 1): Promise<Allowance[]> => {
    const response = await api.get("/pointAllowances", {
      params: {
        page,
      },
    });

    return response.data.response;
  }, []);

  const sendAllowance = useCallback(
    async (data: SendAllowance): Promise<void> => {
      return await api.post("/pointAllowances/register", data);
    },
    []
  );

  const addDocumentAllowance = useCallback(
    async (file: any): Promise<string> => {
      const formData = new FormData();

      formData.append("file_allowance", file);

      const response = await api.post(
        "/pointAllowances/upload/fileAllowance",
        formData
      );

      return response.data.response;
    },
    []
  );

  const getAllowanceTypes = useCallback(async (): Promise<AllowanceType[]> => {
    const response = await api.get("/select/pointAllowanceTypes");

    return response.data.response;
  }, []);

  return {
    sendAllowance,
    addDocumentAllowance,
    getAllowance,
    getAllowanceTypes,
  };
};
