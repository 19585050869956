import { useCallback, useEffect, useState } from "react";
import { Clock, ChevronDown, ChevronUp } from "lucide-react";
import styles from "./styles.module.scss";
import { userApi } from "../../providers/User/service";

export const CardBankHours = () => {
  const [bankHours, setBankHours] = useState<any>(null);
  const [showDetails, setShowDetails] = useState(true);

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startDate, setStartDate] = useState(
    firstDayOfMonth.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);

  const loadBankOfHours = useCallback(async () => {
    const storageUser = localStorage.getItem("@Auth:user");
    if (storageUser) {
      const userData = JSON.parse(storageUser);
      try {
        const response = await userApi.getBankOfHours(
          userData.employee.company_id,
          startDate,
          endDate
        );
        setBankHours(response.data?.response?.[0] || {});
      } catch (error) {
        console.error("Failed to load bank hours:", error);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    loadBankOfHours();
  }, [loadBankOfHours]);

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setDate: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const newDate = new Date(e.target.value);
    if (newDate <= today) {
      setDate(e.target.value);
    }
  };

  const isPositive = Number(bankHours?.hours_balance?.replace(":", ".")) > 0;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.header}>
          <h2 className={styles.title}>Resumo de Horas</h2>
        </div>

        <div className={styles.content}>
          <div className={styles.dateControls}>
            <div className={styles.dateGroup}>
              <label htmlFor="startDate">Data Inicial</label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => handleDateChange(e, setStartDate)}
                max={today.toISOString().split("T")[0]}
              />
            </div>
            <div className={styles.dateGroup}>
              <label htmlFor="endDate">Data Final</label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => handleDateChange(e, setEndDate)}
                max={today.toISOString().split("T")[0]}
              />
            </div>
          </div>

          {bankHours && (
            <div className={styles.summary}>
              <div
                className={`${styles.balance} ${
                  isPositive ? styles.positive : styles.negative
                }`}
              >
                <Clock />
                <span className={styles.hours}>
                  {bankHours.hours_balance || "0:00"}h
                </span>
              </div>

              <button
                className={styles.toggleButton}
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? (
                  <>
                    <ChevronUp />
                    Ocultar detalhes
                  </>
                ) : (
                  <>
                    <ChevronDown />
                    Ver detalhes
                  </>
                )}
              </button>

              {showDetails && (
                <div className={styles.details}>
                  {[
                    {
                      label: "Horas Trabalhadas",
                      value: bankHours.total_worked_minutes,
                    },
                    {
                      label: "Horas Extras",
                      value: bankHours.extra_minutes_worked,
                    },
                    {
                      label: "Horas Faltantes",
                      value: bankHours.missing_minutes,
                    },
                    {
                      label: "Horas Negativas",
                      value: bankHours.total_missing_hours,
                    },
                    {
                      label: "Horas Positivas",
                      value: bankHours.total_positive_hours,
                    },
                  ].map((item, index) => (
                    <div key={index} className={styles.detailItem}>
                      <span className={styles.label}>{item.label}</span>
                      <strong
                        className={`${styles.value} ${
                          item.label === "Horas Positivas"
                            ? styles.positive
                            : ""
                        }`}
                      >
                        {item.value || "0:00"}h
                      </strong>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
