import { format } from "date-fns";
import styles from "./styles.module.scss";

export interface AllowanceProps {
  id: number;
  start_date: string;
  end_date: string;
  is_medical_certificate: boolean;
  doctor_name: string;
  crm: string;
  service_location: string;
  cid: string;
  allowance_reason: string;
  allowance_attachment: string;
  name: string;
  point_allowance_type: string;
  point_allowance_status: string;
  register_by_user: string;
}

export const CardAllowance = ({
  start_date,
  end_date,
  is_medical_certificate,
  doctor_name,
  crm,
  service_location,
  cid,
  allowance_reason,
  allowance_attachment,
  name,
  point_allowance_type,
  point_allowance_status,
  register_by_user,
}: AllowanceProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h3>{name || "N/A"}</h3>
        <span className={styles.status}>{point_allowance_status || "N/A"}</span>
      </div>

      <div className={styles.content}>
        <div className={styles.row}>
          <span>Tipo:</span>
          <p>{point_allowance_type || "N/A"}</p>
        </div>

        <div className={styles.row}>
          <span>Período:</span>
          <p className={styles.period}>
            {start_date ? format(new Date(start_date), "dd/MM/yyyy") : "N/A"} -{" "}
            {end_date ? format(new Date(end_date), "dd/MM/yyyy") : "N/A"}
          </p>
        </div>

        {is_medical_certificate && (
          <>
            <div className={styles.row}>
              <span>Médico:</span>
              <p>{doctor_name || "N/A"}</p>
            </div>

            <div className={styles.row}>
              <span>CRM:</span>
              <p>{crm || "N/A"}</p>
            </div>

            <div className={styles.row}>
              <span>Local:</span>
              <p>{service_location || "N/A"}</p>
            </div>

            <div className={styles.row}>
              <span>CID:</span>
              <p>{cid || "N/A"}</p>
            </div>
          </>
        )}

        <div className={styles.row}>
          <span>Motivo:</span>
          <p>{allowance_reason || "N/A"}</p>
        </div>

        <div className={styles.row}>
          <span>Registrado por:</span>
          <p>{register_by_user || "N/A"}</p>
        </div>

        {allowance_attachment && (
          <div className={styles.attachment}>
            <img src={allowance_attachment} alt="Anexo" />
          </div>
        )}
      </div>
    </div>
  );
};
