import { useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { messaging } from "../../../services/firebase/firebase";
import { onMessage } from "firebase/messaging";
import {
  setNotifications,
  useNotifications,
} from "../../../stores/notifications";

export function NotificationIcon() {
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const notifications = useNotifications();

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      setHasNewNotification(true);
      const newNotifications = [...notifications, payload];
      setNotifications(newNotifications);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <IoMdNotificationsOutline
        style={{ color: "var(--white)" }}
        className="menu"
        size={25}
      />
      {hasNewNotification && (
        <div
          style={{
            position: "absolute",
            top: -5,
            right: -5,
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor: "red",
          }}
        />
      )}
    </div>
  );
}
