import { FiCheck } from "react-icons/fi";
import { Banner } from "../../components/Banner";
import { Layout } from "../../components/Layout";
import style from "./styles.module.scss";
import { CardItemApproval } from "../../components/CardItemApproval";

export default function Approvals() {
  return (
    <Layout>
      <Banner text="Aprovações " icon={<FiCheck />} />
      <div className={style.container}>
        <CardItemApproval />
      </div>
    </Layout>
  );
}
