import { FiClock } from "react-icons/fi";
import { Banner } from "../../components/Banner";
import { Layout } from "../../components/Layout";
import { CardBankHours } from "../../components/CardBankHours";
export default function BankHours() {
  return (
    <Layout>
      <Banner text="Banco de horas" icon={<FiClock />} />

      <CardBankHours />
    </Layout>
  );
}
